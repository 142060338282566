<template>
    <div>
        <div class="flex justify-end">
            <Button text="Initiate payment" @click="showMakePayment = true" v-if="accountData.businessKybDetails && accountData.businessKybDetails.kycStatus == 'APPROVED'            "/>
        </div>
        <Card class="mt-3">
            <div class="flex justify-center" v-if="loading">
                <Spinner/>
            </div>
            <div v-else>
                <div class="grid grid-cols-2" v-if="accountData.wireAccount">
                    <div class="source">
                        <p class="text-lg font-semibold">Name : {{accountData.wireAccount.name}}</p>
                        <p class="mt-4">Status: {{accountData.wireAccount.forcedKycStatus}}</p>
                        <p class="mt-4">Broker Eligible Status: {{accountData.wireAccount.brokerAccessEligibilityStatus}}</p>
                        <p class="mt-4" v-if="accountData.wireAccount && accountData.wireAccount.brokerAccessStatus">Broker Access Status: {{accountData.wireAccount.brokerAccessStatus || 'NIL'}}</p>
                    </div>
                   <div class="pl-3">
                    <p class="font-semibold">Wallet Balance: {{accountData.wallet.balance | formatMoney}}</p>
                    <p class="">Account name: {{accountData.wallet.accountName || 'N/A'}} </p>
                    <p class="flex items-center">Account number: {{accountData.wallet.accountNumber || 'N/A'}}</p>
                    <p class="flex items-center">Bank name: {{accountData.wallet.bankName || 'N/A'}}</p>
                    <div class="flex items-center gap-4 mt-4">
                        <SecondaryButton text="Copy account details" @click="handleCopyAcctDetails"/>
                        <SecondaryButton text="View Wallet history" @click="showWalletHistory = true"/>
                    </div>
                    <div v-if="accountData.wireAccount && accountData.wireAccount.brokerAccessStatus === 'APPROVED'">
                        <Hr/>
                        <p class="font-semibold">Broker Details:</p>
                        <div class="flex items-center" v-if="brokerBalance">
                            <p v-if="brokerBalance.referralCommissionWallet.currency">Referral Wallet: {{ brokerBalance.referralCommissionWallet.balance | formatMoney(brokerBalance.referralCommissionWallet.currency.sign)}}</p>
                            <p class="ml-4" v-if="brokerBalance.paymentCommissionWallet.currency">Payment Wallet: {{ brokerBalance.paymentCommissionWallet.balance | formatMoney(brokerBalance.paymentCommissionWallet.currency.sign)}}</p>
                        </div>
                        <div class="flex mt-4 items-center gap-2">
                            <SecondaryButton text="View Comissions" @click="showCommissionSidebar = true"/>
                            <SecondaryButton text="View Referrals" @click="showReferralList = true"/>
                            <SecondaryButton text="View Registered Senders" @click="showSenderInformation = true" v-if="accountData.wireAccount && accountData.wireAccount.brokerAccessStatus"/>
                        </div>
                    </div>
                   
                   </div>
                </div>
            </div>
        </Card>
        <KybDetails @updateKyb=" getAccountDetails()" :businessDirector="businessDirector" :accountData="accountData" class="mt-4" :businessRegistrationInfo="businessRegistrationInfo"/>
        <ReferralList :accountDetails="accountData" v-if="showReferralList" @close="showReferralList = false"/>
        <ReferralComission :accountDetails="accountData" v-if="showCommissionSidebar" @close="showCommissionSidebar = false"/>
        <WalletHistory :accountDetails="accountData" v-if="showWalletHistory" @close="showWalletHistory = false"/>
        <Senders :accountDetails="accountData" v-if="showSenderInformation" @close="showSenderInformation = false"/>
        <MakePayment :wireAccountData="accountData" display v-if="showMakePayment" @close="showMakePayment = false"/>
    </div>
   
</template>
<script>
import {VIEW_ACCOUNT_DETAILS, FORCED_BROKER_ELIGIBILITY, UPDATE_BROKER_ACCESS_STATUS,GET_BROKER_WALLET_BALANCE, DECLINE_DIRECTOR, DECLINE_KYB, } from '@/utils/api/wire'
import {SecondaryButton, Button} from '@/UI/Button'
import Spinner from '@/UI/Spinner'
import file from '@/mixins/file'
import Hr from '@/UI/Hr'
import UpdateKyb from './UpdateKyb'
import RateDiscount from './RateDiscount'
import {mapActions} from 'vuex'
import {Modal, RightSidebar} from '@/UI/Modals'
import {Select} from '@/UI/Input'
import EnterBusinessInfo from './OTC/EnterBusinessInfo.vue'
import AddDirectorsDetails from './OTC/AddDirectorsDetails.vue'
import {Input} from '@/UI/Input'
import copy from '@/mixins/copy'
import KybDetails from './KybDetails.vue'
export default {
    components:{
        Spinner,
        SecondaryButton,
        Hr,
        UpdateKyb,
        RateDiscount,
        Modal,
        RightSidebar,
        Select,
        Button,
        ReferralList:() => import('./ReferralList.vue'),
        ReferralComission:() => import('./ReferralComission'),
        WalletHistory:() => import('./WalletHistory.vue'),
        MakePayment: () => import('./MakePayment'),
        Senders: () => import('./Senders.vue'),
        EnterBusinessInfo,
        AddDirectorsDetails,
        Input,
        KybDetails
    },
    mixins:[file, copy],
    data:() => ({
        loading: false,
        businessRegistrationInfo:{},
        businessDirector:[],
        showBusinessInfo: false,
        businessInfoAdded: false,
        showAddDirective: false,
        accountData:{},
        // showUpdateKybModal: false,
        // showDiscountModal: false,
        // brokerAccessLoading: false,
        // brokerAccessOptions:['APPROVED', 'DECLINED', 'NONE'],
        // brokerAccess:'',
        // showBrokerAccessModal: false,
        // forceLoading: false,
        // showForceUpdateModal: false,
        // forceUpdateOptions: ['ON','OFF', 'NEUTRAL'],
        // forceBrokerUpdate:'',
        // brokerWalletLoading: false,
        brokerBalance:{
            referralCommissionWallet:{},
            paymentCommissionWallet:{}
        },
        showReferralList: false,
        showCommissionSidebar: false,
        showWalletHistory: false,
        showSenderInformation: false,
        showMakePayment: false,
        showDeclinedModalforKyb: false,
        declinedReason:'',
        declineLoading: false,
        declineType:'',
        directorId:''
    }),
    methods:{
        ...mapActions('notification', ['showAlert']),
        ...mapActions('loading', ['setLoading']),
        ...mapActions('wire', ['getCurrencyMappings']),
        getAccountDetails(){
            this.loading = true
            VIEW_ACCOUNT_DETAILS(this.$route.params.id, this.$route.query.wireAccountId)
                .then(({data}) => {
                    
                this.businessRegistrationInfo = data.data.businessKybDetails.businessRegistrationInfo
                this.businessDirector= data.data.businessKybDetails.businessDirector
                this.accountData = data.data
                this.loading = false
            }).catch(() => {
                this.loading = false
            })
        },
    //     downloadItem(id) {
    //         window.open(
    //         `${process.env.VUE_APP_BASE_URL}/media/image/${id}`,
    //         "_blank" // open in a new window.
    //         );
    //   },
      
    //   handleKybSuccess(){
    //     this.showUpdateKybModal = false;
    //     this.getAccountDetails()
    //   },
    //   updateBrokerAccess(){
    //     this.brokerAccessLoading = true;
    //     UPDATE_BROKER_ACCESS_STATUS({
    //         wireAccountId: this.accountData.wireAccount.id,
    //         brokerAccessStatus: this.brokerAccess
    //     })
    //         .then(() => {
    //             this.showAlert({
    //                 display: true,
    //                 description:'Update success',
    //                 title:'Success',
    //                 type: 'success'
    //             })
    //             this.brokerAccessLoading  = false;
    //             this.showBrokerAccessModal = false;
    //             this.getAccountDetails()
    //         }).catch(() => {
    //             this.brokerAccessLoading  = false
    //         })
    //   },
    //   forceBrokerEligibility(){
    //     this.forceLoading = true;
    //     FORCED_BROKER_ELIGIBILITY({
    //         wireAccountId: this.accountData.wireAccount.id,
    //         forcedBrokerAccessEligibilityStatus: this.forceBrokerUpdate
    //     })
    //         .then(() => {
    //             this.showAlert({
    //                 display: true,
    //                 description:'Update success',
    //                 title:'Success',
    //                 type: 'success'
    //             })
    //             this.forceLoading = false;
    //             this.getAccountDetails()
    //             this.showForceUpdateModal = false
    //         }).catch(() => {
    //             this.forceLoading = false
    //         })
        
    //   },
      getBrokerWalletBalance(){
        this.brokerWalletLoading = true;
        GET_BROKER_WALLET_BALANCE(this.$route.params.id)
            .then(({data}) => {
                this.brokerBalance = data.data;
                this.brokerWalletLoading = false
            })
            .catch(() => {
                this.brokerWalletLoading = false
            })
      },
    //   getAccountRefferalList(){

    //   },
    //   handleBusinessInfo(){
    //     this.showBusinessInfo = !this.showBusinessInfo
    //   },
    //   handleAddDirectives(){
    //     if(this.businessRegistrationInfo && this.businessRegistrationInfo.id){
    //         this.showAddDirective = !this.showAddDirective
    //     }
    //   },
    
      handleAddBusinessInfo(){
        this.showBusinessInfo = false;
        this.showAlert({
            display: true,
            description:'Update successful',
            title:'Success',
            type: 'success'
        })
        this.getAccountDetails();
      },
    //   handleDirectorDetails(){
    //     this.showAddDirective = false
    //     this.showAlert({
    //         display: true,
    //         description:'Update successfull',
    //         title:'Success',
    //         type: 'success'
    //     })
    //     this.getAccountDetails();
    //   },
    //   declineDirector(){
    //     this.declineLoading = true;
    //     DECLINE_DIRECTOR({
    //         id: this.directorId,
    //         declinedReason: this.declinedReason
    //     }).then(() => {
    //         this.getAccountDetails();
    //         this.showAlert({
    //         display: true,
    //         description:'Update successful',
    //         title:'Success',
    //         type: 'success'
    //     })
    //         this.declineLoading = false;
    //         this.showDeclinedModalforKyb = false
    //     }).catch(() => {
    //         this.declineLoading = false
    //     })
    //   },
    //   declineKyb(){
    //     this.declineLoading = true;
    //     DECLINE_KYB({
    //         id: this.accountData.businessKybDetails.businessRegistrationInfo.id,
    //         declinedReason: this.declinedReason
    //     }).then(() => {
    //         this.getAccountDetails();
    //         this.showAlert({
    //         display: true,
    //         description:'Update successful',
    //         title:'Success',
    //         type: 'success'
    //     })
    //         this.declineLoading = false;
    //         this.showDeclinedModalforKyb = false
    //     }).catch(() => {
    //         this.declineLoading = false
    //     })
    //   },
      handleShowDeclineModal(type){
        this.declineType = type;
        this.showDeclinedModalforKyb = true
      },
      handleCopyAcctDetails(){
        let data =  `
            Account name: ${this.accountData.wallet.accountName}

            Bank name : ${this.accountData.wallet.bankName}

            Account number : ${this.accountData.wallet.accountNumber}
       `
        this.copy(data)
      },
    //   disableApproveKyc(){
    //    if((this.accountData.businessKybDetails && !this.accountData.businessKybDetails.businessRegistrationInfo) || (this.accountData.businessKybDetails && !this.accountData.businessKybDetails.businessDirector.length)){
    //     return true
    //    }
    //    if(this.accountData.businessKybDetails && (this.accountData.businessKybDetails &&  this.accountData.businessKybDetails.businessRegistrationInfo.registeredBusinessType == 'LLC') && (this.accountData.businessKybDetails && this.accountData.businessKybDetails.businessDirector.length < 2) ){
    //         return true
    //    }
    //   }
    },
    mounted(){
        this.getAccountDetails();
        this.getBrokerWalletBalance();
        this.getCurrencyMappings()
    }
}
</script>
<style scoped>
.source{
    border-right: 1px solid rgb(242, 237, 253)
}
</style>